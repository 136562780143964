<script setup>
import { ref, defineEmits } from "vue";
const topic = ref("");

const emit = defineEmits(["close"]);

const topics = JSON.parse(localStorage.getItem("topics"));
let highestId = 0;
for (const topic of topics) {
  // strip non numbers from topic.id
  const currId = parseInt(topic.id.replace(/\D/g, ""));
  if (currId > highestId) {
    highestId = currId;
  }
}

const submit = async () => {
  // POST to API
  const response = await fetch(
    "https://dabvy0os1c.execute-api.eu-west-1.amazonaws.com/prod/update-topic",
    {
      method: "POST",
      body: JSON.stringify({
        username: localStorage.getItem("username"),
        password: localStorage.getItem("password"),
        id: highestId + 1,
        topic: topic.value,
      }),
      headers: {
        "Content-type": "application/json;",
      },
    }
  );
  if (response.status === 201) {
    // Success
    localStorage.setItem(
      "topics",
      JSON.stringify([
        ...topics,
        { id: (highestId + 1).toString(), topic: topic.value },
      ])
    );
    emit("close");
  } else {
    console.error("error: ", response);
  }
};
</script>

<template>
  <section
    class="fixed inset-0 z-50 flex items-center justify-center flex-wrap py-28 bg-neutral-500 bg-opacity-80 overflow-y-auto"
  >
    <div class="container bg-white rounded-3xl">
      <div class="max-w-md mx-auto">
        <div class="my-5 text-center">
          <div class="mb-6">
            <span class="text-gray-500">Add Topic</span>
          </div>
          <p>Make me a...</p>
          <div class="mb-3 flex p-4 bg-gray-200 rounded">
            <input
              class="w-full text-xs bg-gray-200 outline-none"
              type="text"
              placeholder="Topic Name"
              v-model="topic"
            />
          </div>
          <div class="text-center">
            <button
              class="w-full py-4 bg-green-600 hover:bg-green-700 rounded text-sm font-bold text-gray-50 transition duration-200"
              @click="submit"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
