import perTopic from "@/utils/perTopic";

const cleanPerTopic = async (id, reload) => {
  const conversations = JSON.parse(localStorage.getItem("conversations"));
  const conversation = conversations.find((c) => c.id === id);
  conversations.splice(conversations.indexOf(conversation), 1);
  conversation.topics = {};
  conversations.push(conversation);
  localStorage.setItem("conversations", JSON.stringify(conversations));
  reload();
  perTopic(id, reload);
};

export default cleanPerTopic;
