<script setup>
import { ref } from "vue";
import LoginModal from "./LoginModal.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import AddTopicModal from "./AddTopicModal.vue";
import AddPromptsModal from "./AddPromptsModal.vue";
import cleanPerTopic from "@/utils/cleanPerTopic";
import perTopic from "@/utils/perTopic";
const showLoginModal = ref(false);
const showAddTopicModal = ref(false);
const showAddPromptModal = ref(false);
const expanded = ref(false);
const expandedId = ref(0);
// check if token in local storage
const token = localStorage.getItem("token");
if (!token) {
  showLoginModal.value = true;
}

const getAllConversations = async () => {
  const response = await fetch(
    "https://dabvy0os1c.execute-api.eu-west-1.amazonaws.com/prod/get-all-conversations",
    {
      method: "POST",
      body: JSON.stringify({
        password: localStorage.getItem("password"),
      }),
      headers: {
        "Content-type": "application/json;",
      },
    }
  );
  if (response.status === 201) {
    // Success
    const data = await response.json();
    localStorage.setItem("conversations", JSON.stringify(data.result));
    conversations.value = data.result;
  } else {
    localStorage.setItem("conversations", JSON.stringify({}));
    console.error("error: ", response);
  }
};

const getAllTopics = async () => {
  const response = await fetch(
    "https://dabvy0os1c.execute-api.eu-west-1.amazonaws.com/prod/get-all-topics",
    {
      method: "POST",
      body: JSON.stringify({
        password: localStorage.getItem("password"),
      }),
      headers: {
        "Content-type": "application/json;",
      },
    }
  );
  if (response.status === 201) {
    // Success
    const data = await response.json();
    localStorage.setItem("topics", JSON.stringify(data.result.result));
    topics.value = data.result.result;
  } else {
    localStorage.setItem("topics", JSON.stringify([]));
    console.error("error: ", response);
  }
};

const conversations = ref(JSON.parse(localStorage.getItem("conversations")));
if (!conversations.value) {
  conversations.value = [];
}
if (token) {
  getAllConversations();
}

const topics = ref(JSON.parse(localStorage.getItem("topics")));
if (token) {
  getAllTopics();
}

const loggedIn = () => {
  showLoginModal.value = false;
  getAllConversations();
  getAllTopics();
};

const closeTopicModal = () => {
  showAddTopicModal.value = false;
  topics.value = JSON.parse(localStorage.getItem("topics"));
};

const closePromptModal = () => {
  showAddPromptModal.value = false;
  conversations.value = JSON.parse(localStorage.getItem("conversations"));
};

const reload = () => {
  conversations.value = JSON.parse(localStorage.getItem("conversations"));
};

const deleteConversation = (conversation) => {
  const newConversations = conversations.value.filter(
    (c) => c.id !== conversation.id
  );
  conversations.value = newConversations;
  localStorage.setItem("conversations", JSON.stringify(newConversations));
  fetch(
    "https://dabvy0os1c.execute-api.eu-west-1.amazonaws.com/prod/delete-conversation",
    {
      method: "POST",
      body: JSON.stringify({
        password: localStorage.getItem("password"),
        username: localStorage.getItem("username"),
        id: conversation.id,
      }),
      headers: {
        "Content-type": "application/json;",
      },
    }
  );
};
const findHighestId = () => {
  const conversationsForId = JSON.parse(localStorage.getItem("conversations"));
  let highestId = 0;
  for (const conversation of conversationsForId) {
    // strip non numbers from topic.id
    const currId = parseInt(conversation.id.replace(/\D/g, ""));
    if (currId > highestId) {
      highestId = currId;
    }
  }
  return highestId;
};

const createPrompt = async (item) => {
  const label = item.label;
  const systemPrompt = item.systemPrompt;
  const questionsPrompt = item.questionsPrompt;
  const generationPrompt = item.generationPrompt;
  const highestId = findHighestId();
  // POST to API
  const conversation = {
    label: label,
    systemPrompt: systemPrompt,
    userPrompts: [questionsPrompt, generationPrompt],
    id: (highestId + 1).toString(),
  };
  const response = await fetch(
    "https://dabvy0os1c.execute-api.eu-west-1.amazonaws.com/prod/update-conversation",
    {
      method: "POST",
      body: JSON.stringify({
        username: localStorage.getItem("username"),
        password: localStorage.getItem("password"),
        id: highestId + 1,
        conversation,
      }),
      headers: {
        "Content-type": "application/json;",
      },
    }
  );
  if (response.status === 201) {
    // Success
    let newConversations = localStorage.getItem("conversations");
    if (!newConversations) {
      newConversations = [];
    } else {
      newConversations = JSON.parse(newConversations);
    }
    newConversations.push(conversation);
    localStorage.setItem("conversations", JSON.stringify(newConversations));
    closePromptModal();
    perTopic(conversation.id, reload);
  } else {
    console.error("error: ", response);
  }
};
</script>

<template>
  <div class="w-full flex">
    <button
      class="w-1/3 ml-10 py-4 px-12 bg-green-600 hover:bg-green-700 rounded text-xl my-2 font-bold text-gray-50 transition duration-200"
      @click="showAddPromptModal = true"
    >
      Add Prompts
    </button>
    <button
      class="w-1/3 ml-auto mr-10 py-4 px-12 bg-orange-600 hover:bg-orange-700 rounded text-xl my-2 font-bold text-gray-50 transition duration-200"
      @click="showAddTopicModal = true"
    >
      Add Topic
    </button>
  </div>
  <LoginModal v-if="showLoginModal" @close="loggedIn" />
  <AddTopicModal v-if="showAddTopicModal" @close="closeTopicModal" />
  <AddPromptsModal
    v-if="showAddPromptModal"
    @close="closePromptModal"
    @submit="createPrompt($event)"
  />
  <section class="lex flex-col overflow-x-auto">
    <div class="inline-block min-w-full py-2 sm:px-6 lg:px-8">
      <div class="inline-block min-w-full py-2 sm:px-6 lg:px-8">
        <div class="overflow-x-auto">
          <table
            class="min-w-full text-left text-sm font-light border dark:border-neutral-500"
          >
            <thead class="border-b font-medium dark:border-neutral-500">
              <tr>
                <th
                  scope="col"
                  :rowspan="expanded ? 2 : 1"
                  class="border-r px-6 py-4 dark:border-neutral-500 text-center"
                >
                  Label
                </th>
                <th
                  scope="col"
                  :rowspan="expanded ? 2 : 1"
                  class="border-r px-6 py-4 dark:border-neutral-500 text-center"
                >
                  System prompt
                </th>
                <th
                  scope="col"
                  :rowspan="expanded ? 2 : 1"
                  class="border-r px-6 py-4 dark:border-neutral-500 text-center"
                >
                  User prompts
                </th>
                <th
                  v-for="topic in topics"
                  :key="topic.id"
                  scope="col"
                  :colspan="expandedId === topic.id ? 5 : 1"
                  :rowspan="expandedId !== topic.id && expanded ? 2 : 1"
                  class="border-r px-6 py-4 dark:border-neutral-500 text-center"
                >
                  {{ topic.topic }}

                  <FontAwesomeIcon
                    :icon="
                      expandedId === topic.id ? 'chevron-left' : 'chevron-right'
                    "
                    class="cursor-pointer text-green-700 h-4 ml-3 mr-2"
                    @click="
                      expandedId === topic.id
                        ? (expandedId = 0)
                        : (expandedId = topic.id);
                      expandedId === topic.id
                        ? (expanded = true)
                        : (expanded = false);
                    "
                  />
                </th>
              </tr>
              <tr v-if="expanded">
                <th
                  scope="col"
                  class="border-r px-6 py-4 dark:border-neutral-500 text-center"
                >
                  1
                </th>
                <th
                  scope="col"
                  class="border-r px-6 py-4 dark:border-neutral-500 text-center"
                >
                  2
                </th>
                <th
                  scope="col"
                  class="border-r px-6 py-4 dark:border-neutral-500 text-center"
                >
                  3
                </th>
                <th
                  scope="col"
                  class="border-r px-6 py-4 dark:border-neutral-500 text-center"
                >
                  4
                </th>
                <th
                  scope="col"
                  class="border-r px-6 py-4 dark:border-neutral-500 text-center"
                >
                  5
                </th>
              </tr>
            </thead>
            <tbody>
              <template
                v-for="conversation in conversations.slice().reverse()"
                :key="conversation.id"
              >
                <tr class="border-b dark:border-neutral-500">
                  <td
                    class="border-r px-6 py-4 font-medium dark:border-neutral-500 min-w-fit text-center"
                    rowspan="2"
                  >
                    {{ conversation.label }}
                    <br />
                    <button
                      class="p-2 bg-green-600 hover:bg-green-700 rounded my-3 text-sm font-bold text-gray-50 transition duration-200"
                      @click="cleanPerTopic(conversation.id, reload)"
                    >
                      Generate
                    </button>
                    <br />
                    <button
                      class="p-2 bg-red-600 hover:bg-red-700 rounded text-sm font-bold text-gray-50 transition duration-200"
                      @click="deleteConversation(conversation)"
                    >
                      Delete
                    </button>
                  </td>
                  <td
                    class="border-r px-6 py-4 dark:border-neutral-500 min-w-fit"
                    rowspan="2"
                  >
                    {{ conversation.systemPrompt }}
                  </td>
                  <td
                    class="border-r px-6 py-4 dark:border-neutral-500 min-w-fit"
                  >
                    {{ conversation.userPrompts[0] }}
                  </td>
                  <template v-for="topic in topics" :key="topic.id">
                    <template v-if="expandedId === topic.id">
                      <template
                        v-if="
                          !conversation.topics || !conversation.topics[topic.id]
                        "
                      >
                        <td
                          class="border-r px-6 py-4 dark:border-neutral-500 min-w-fit"
                          v-for="i in 5"
                          :key="i"
                        >
                          -
                        </td>
                      </template>
                      <template v-else>
                        <td
                          class="border-r px-6 py-4 dark:border-neutral-500 whitespace-pre-line min-w-fit"
                          v-for="i in 5"
                          :key="i"
                        >
                          {{
                            conversation.topics[topic.id].responses[i - 1]
                              ? conversation.topics[topic.id].responses[i - 1]
                                  .prompts[0]
                              : " - "
                          }}
                        </td>
                      </template>
                    </template>
                    <template v-else>
                      <template
                        v-if="
                          !conversation.topics || !conversation.topics[topic.id]
                        "
                      >
                        <td
                          class="border-r px-6 py-4 dark:border-neutral-500 min-w-fit"
                        >
                          -
                        </td>
                      </template>
                      <template v-else>
                        <td
                          class="border-r px-6 py-4 dark:border-neutral-500 whitespace-pre-line min-w-fit"
                        >
                          {{
                            conversation.topics[topic.id].responses[0]
                              .prompts[0]
                              ? conversation.topics[topic.id].responses[0]
                                  .prompts[0]
                              : ""
                          }}
                        </td>
                      </template>
                    </template>
                  </template>
                </tr>
                <tr
                  class="border-b dark:border-neutral-500"
                  v-for="promptIndex in conversation.userPrompts.length - 1"
                  :key="promptIndex"
                >
                  <td
                    class="border-r px-6 py-4 dark:border-neutral-500 min-w-fit"
                  >
                    {{ conversation.userPrompts[promptIndex] }}
                  </td>
                  <template v-for="topic in topics" :key="topic.id">
                    <template v-if="expandedId === topic.id">
                      <template
                        v-if="
                          !conversation.topics || !conversation.topics[topic.id]
                        "
                      >
                        <td
                          class="border-r px-6 py-4 dark:border-neutral-500 min-w-fit"
                          v-for="i in 5"
                          :key="i"
                        >
                          -
                        </td>
                      </template>
                      <template v-else>
                        <td
                          class="border-r px-6 py-4 dark:border-neutral-500 whitespace-pre-line min-w-fit"
                          v-for="i in 5"
                          :key="i"
                        >
                          {{
                            conversation.topics[topic.id].responses[i - 1]
                              ? conversation.topics[topic.id].responses[i - 1]
                                  .prompts[promptIndex]
                              : " - "
                          }}
                        </td>
                      </template>
                    </template>
                    <template v-else>
                      <template
                        v-if="
                          !conversation.topics || !conversation.topics[topic.id]
                        "
                      >
                        <td
                          class="border-r px-6 py-4 dark:border-neutral-500 min-w-fit"
                        >
                          -
                        </td>
                      </template>
                      <template v-else>
                        <td
                          class="border-r px-6 py-4 dark:border-neutral-500 whitespace-pre-line min-w-fit"
                        >
                          {{
                            conversation.topics[topic.id].responses[0].prompts[
                              promptIndex
                            ]
                              ? conversation.topics[topic.id].responses[0]
                                  .prompts[promptIndex]
                              : ""
                          }}
                        </td>
                      </template>
                    </template>
                  </template>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>
```
