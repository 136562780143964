import generateNew from "@/utils/generateNew";

const perTopic = async (id, reload) => {
  // get Topics
  const topics = JSON.parse(localStorage.getItem("topics"));
  // loop through topics
  for (let i = 0; i < 5; i++) {
    for (let j = 0; j < topics.length; j++) {
      const topic = topics[j];
      await generateNew(id, topic, reload);
    }
  }
};

export default perTopic;
