const savePrompt = async (id, conversation) => {
  const response = await fetch(
    "https://dabvy0os1c.execute-api.eu-west-1.amazonaws.com/prod/update-conversation",
    {
      method: "POST",
      body: JSON.stringify({
        username: localStorage.getItem("username"),
        password: localStorage.getItem("password"),
        id,
        conversation,
      }),
      headers: {
        "Content-type": "application/json;",
      },
    }
  );
  if (response.status === 201) {
    return true;
  } else {
    return false;
  }
};

export default savePrompt;
