const { Configuration, OpenAIApi } = require("openai");
import savePrompt from "@/utils/savePrompt";

const generateNew = async (id, topic, reload) => {
  const configuration = new Configuration({
    apiKey: localStorage.getItem("token"),
  });
  const openai = new OpenAIApi(configuration);
  const conversations = JSON.parse(localStorage.getItem("conversations"));
  const conversation = conversations.find((c) => c.id === id);
  // pop conversation from conversations
  conversations.splice(conversations.indexOf(conversation), 1);
  // replace {topic} with the topic
  let systemPrompt = "";
  if (conversation.systemPrompt) {
    systemPrompt = conversation.systemPrompt.replace("{topic}", topic.topic);
  }
  let userPrompt = "";
  if (conversation.userPrompts[0]) {
    userPrompt = conversation.userPrompts[0].replace("{topic}", topic.topic);
    // eslint-disable-next-line prettier/prettier
    userPrompt += ' Format this as a JSON object with the following structure: [{"question": "","suggestedAnswers": []}]'
  
  }
  let response = await openai.createChatCompletion({
    model: "gpt-3.5-turbo",
    messages: [
      { role: "system", content: systemPrompt },
      { role: "user", content: userPrompt },
    ],
  });

  const tokens = response.data.usage.total_tokens;
  response = response.data.choices[0].message.content.replace(
    /(\r\n|\n|\r|\t)/gm,
    ""
  );
  // remove backticks from response
  response = response.replace("```json", "");
  // remove all backticks
  response = response.replace(/`/g, "");

  let questions = "";
  let responseLength = 0;
  try {
    let asJson = JSON.parse(response);
    // for each question, add the key id and set it to the index of the question
    if (asJson.questions) {
      asJson = asJson.questions;
    }

    let asText = `(Tokens used: ${tokens}, cost: $${
      (tokens / 1000) * 0.002
    }) \n\n`;
    asJson.forEach((question) => {
      asText += `\n ${question.question} \n`;
      question.suggestedAnswers.forEach((answer) => {
        asText += `\t ${answer} \n`;
      });
    });

    // add the key topics to conversation
    // check if topics exists
    if (!conversation.topics) {
      conversation.topics = {};
    }
    if (!conversation.topics[topic.id]) {
      conversation.topics[topic.id] = {};
    }
    const tempPrompt = {
      prompts: [asText],
    };

    if (conversation.topics[topic.id].responses) {
      responseLength = conversation.topics[topic.id].responses.length;
    }
    if (responseLength === 0) {
      conversation.topics[topic.id] = { responses: [tempPrompt] };
    } else {
      conversation.topics[topic.id].responses.push(tempPrompt);
    }
    // add the conversation back to conversations at the top
    conversations.push(conversation);
    localStorage.setItem("conversations", JSON.stringify(conversations));
    reload();
    savePrompt(id, conversation);
    // remove conversation from conversations
    conversations.splice(conversations.indexOf(conversation), 1);

    asJson.forEach((question) => {
      questions += `Question: ${question.question} Answer: ${question.suggestedAnswers[0]} `;
    });
  } catch (e) {
    // add the key topics to conversation
    // check if topics exists
    if (!conversation.topics) {
      conversation.topics = {};
    }
    if (!conversation.topics[topic.id]) {
      conversation.topics[topic.id] = {};
    }
    let asText = `(Tokens used: ${tokens}, cost: $${(
      (tokens / 1000) *
      0.002
    ).toFixed(5)}) \n\n`;
    const tempPrompt = {
      prompts: [asText + response.toString()],
    };

    let responseLength = 0;
    if (conversation.topics[topic.id].responses) {
      responseLength = conversation.topics[topic.id].responses.length;
    }
    if (responseLength === 0) {
      conversation.topics[topic.id] = { responses: [tempPrompt] };
    } else {
      conversation.topics[topic.id].responses.push(tempPrompt);
    }
    conversations[conversation.id] = conversation;
    localStorage.setItem("conversations", JSON.stringify(conversations));
    reload();
    savePrompt(id, conversation);
    return;
  }

  let userPromptTwo = "";
  if (conversation.userPrompts[1]) {
    userPromptTwo = conversation.userPrompts[1].replace("{topic}", topic.topic);
    userPromptTwo = userPromptTwo.replace("{questions}", questions);
    userPromptTwo +=
      " Format this as a JSON object with the following structure: {'output': ''}";
  }
  let responseTwo = await openai.createChatCompletion({
    model: "gpt-3.5-turbo",
    messages: [
      { role: "system", content: systemPrompt },
      { role: "user", content: userPromptTwo },
    ],
  });

  const tokensTwo = responseTwo.data.usage.total_tokens;
  responseTwo = responseTwo.data.choices[0].message.content.replace(
    /(\r\n|\n|\r|\t)/gm,
    ""
  );
  responseTwo = responseTwo.replace(/`/g, "");

  try {
    const tempPromptTwo = {
      prompts: [
        conversation.topics[topic.id].responses[responseLength].prompts[0],
        `(Tokens used: ${tokensTwo}, cost: $${(
          (tokensTwo / 1000) *
          0.002
        ).toFixed(5)}) \n\n ${JSON.parse(responseTwo).output}`,
      ],
    };
    conversation.topics[topic.id].responses[responseLength] = tempPromptTwo;
    conversations.push(conversation);
    localStorage.setItem("conversations", JSON.stringify(conversations));
    reload();
    savePrompt(id, conversation);
  } catch (e) {
    try {
      // eslint-disable-next-line quotes
      responseTwo = responseTwo.replace(/'/g, '"');
      const tempPromptTwo = {
        prompts: [
          conversation.topics[topic.id].responses[responseLength].prompts[0],
          `(Tokens used: ${tokensTwo}, cost: $${(
            (tokensTwo / 1000) *
            0.002
          ).toFixed(5)}) \n\n ${JSON.parse(responseTwo).output}`,
        ],
      };
      conversation.topics[topic.id].responses[responseLength] = tempPromptTwo;
      conversations.push(conversation);
      localStorage.setItem("conversations", JSON.stringify(conversations));
      reload();
      savePrompt(id, conversation);
    } catch (e) {
      // remove the first occurance of the word "output"
      responseTwo = responseTwo.replace("output", "");
      // remove double quotes
      responseTwo = responseTwo.replace(/"/g, "");
      // remove curly braces
      responseTwo = responseTwo.replace(/{/g, "");
      // remove colons
      responseTwo = responseTwo.replace(/:/g, "");
      const tempPromptTwo = {
        prompts: [
          conversation.topics[topic.id].responses[responseLength].prompts[0],
          `(Tokens used: ${tokensTwo}, cost: $${(
            (tokensTwo / 1000) *
            0.002
          ).toFixed(5)}) \n\n ${responseTwo}`,
        ],
      };
      conversation.topics[topic.id].responses[responseLength] = tempPromptTwo;
      conversations.push(conversation);
      localStorage.setItem("conversations", JSON.stringify(conversations));
      reload();
      savePrompt(id, conversation);
    }
  }
  return;
};

export default generateNew;
