<script setup>
import { ref, defineEmits } from "vue";
const label = ref("");
const systemPrompt = ref("");
const questionsPrompt = ref("");
const generationPrompt = ref("");

const emit = defineEmits(["close", "submit"]);

const submit = () => {
  emit("submit", {
    label: label.value,
    systemPrompt: systemPrompt.value,
    questionsPrompt: questionsPrompt.value,
    generationPrompt: generationPrompt.value,
  });
};
</script>

<template>
  <section
    class="fixed inset-0 z-50 flex items-center justify-center flex-wrap py-28 bg-neutral-500 bg-opacity-80 overflow-y-auto"
  >
    <div class="container bg-white rounded-3xl">
      <div class="max-w-5xl mx-auto">
        <div class="my-5 text-center">
          <div class="mb-6">
            <span class="text-gray-500">Add Prompts</span>
            <p class="text-gray-800">
              Use <b>{topic}</b> to replace with topic and <b>{questions}</b> to
              replace with
              <em class="text-black">Question: xyz, Answer: xyz</em> for each
              returned question. <br />
              Instructions for formatting will be to the end of your prompts.
            </p>
          </div>
          <p>Label</p>
          <div class="mb-3 flex p-4 bg-gray-200 rounded">
            <input
              class="w-full text-sm bg-gray-200 outline-none"
              type="text"
              placeholder="Label"
              v-model="label"
            />
          </div>
          <p>System Prompt</p>
          <div class="mb-3 flex p-4 bg-gray-200 rounded">
            <textarea
              class="w-full text-sm bg-gray-200 outline-none"
              type="text"
              placeholder="System Prompt"
              v-model="systemPrompt"
            />
          </div>
          <p>Questions Prompt</p>
          <div class="mb-3 flex p-4 bg-gray-200 rounded">
            <textarea
              class="w-full text-sm bg-gray-200 outline-none"
              type="text"
              placeholder="Questions prompt"
              v-model="questionsPrompt"
            />
          </div>
          <p>Generation Prompt</p>
          <div class="mb-3 flex p-4 bg-gray-200 rounded">
            <textarea
              class="w-full text-sm bg-gray-200 outline-none"
              type="text"
              placeholder="Generation prompt"
              v-model="generationPrompt"
            />
          </div>
          <div class="text-center">
            <button
              class="w-full py-4 bg-green-600 hover:bg-green-700 rounded text-sm font-bold text-gray-50 transition duration-200"
              @click="submit"
            >
              Generate
            </button>
            <button
              class="w-full mt-3 py-4 bg-red-600 hover:bg-red-700 rounded text-sm font-bold text-gray-50 transition duration-200"
              @click="emit('close')"
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
