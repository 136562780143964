<script setup>
import { ref, defineEmits } from "vue";
const username = ref("");
const password = ref("");
const error = ref("bg-gray-200");

const emit = defineEmits(["close"]);

const submit = async () => {
  // POST to API
  const response = await fetch(
    "https://dabvy0os1c.execute-api.eu-west-1.amazonaws.com/prod/get-api",
    {
      method: "POST",
      body: JSON.stringify({
        password: password.value,
      }),
      headers: {
        "Content-type": "application/json;",
      },
    }
  );
  if (response.status === 201) {
    // Success
    const data = await response.json();
    localStorage.setItem("username", username.value);
    localStorage.setItem("password", password.value);
    localStorage.setItem("token", data.apiKey);
    emit("close");
  } else {
    // Error
    error.value = "bg-red-300";
    // after 1 second, reset error
    setTimeout(() => {
      error.value = "bg-gray-200";
    }, 2000);
  }
};
</script>

<template>
  <section
    class="fixed inset-0 z-50 flex items-center justify-center flex-wrap py-28 bg-neutral-500 bg-opacity-80 overflow-y-auto"
  >
    <div class="container bg-white rounded-3xl">
      <div class="max-w-md mx-auto">
        <div class="my-5 text-center">
          <div class="mb-6">
            <span class="text-gray-500">Log In</span>
          </div>
          <div class="mb-3 flex p-4 bg-gray-200 rounded">
            <input
              class="w-full text-xs bg-gray-200 outline-none"
              type="text"
              placeholder="Name"
              v-model="username"
            />
          </div>
          <div class="mb-6 flex p-4 rounded" :class="error">
            <input
              class="w-full text-xs outline-none"
              :class="error"
              type="password"
              placeholder="Enter your password"
              v-model="password"
              @keyup.enter="submit"
            />
          </div>
          <div class="text-center">
            <button
              class="w-full py-4 bg-green-600 hover:bg-green-700 rounded text-sm font-bold text-gray-50 transition duration-200"
              @click="submit"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
